<template>
  <div>
    <DropdownItem :closeOnClick="false" @click="toggleDropdown" ref="trigger" :active="showDropdown">
      <div class="flex justify-between items-center">
        <div>{{ t("change_organisation") }}</div>
        <Icon :size="ComponentSize.sm" src="CaretRight" />
      </div>
    </DropdownItem>
    <Dropdown
      v-model:open="showDropdown"
      :toggleElement="trigger"
      ref="dropdown"
      :calculatePosition="calculatePosition"
    >
      <div class="flex flex-col max-w-[312px] p-1.5 gap-0.5">
        <DropdownItem
          class="pl-1.5 py-1 pr-3"
          v-for="(account, idx) in accounts"
          :closeOnClick="false"
          size="hug"
          :selected="isCurrentAccount(account)"
          :active="isCurrentAccount(account)"
          @click="() => setCurrentAccount(account)"
        >
          <template #custom>
            <div class="flex gap-3 self-stretch w-full items-center">
              <Avatar
                random-color
                :index="idx"
                :entity="{
                  name: account.account.name,
                  image: account.account.image,
                }"
                size="xs"
              />
              <div class="mr-auto flex flex-col overflow-hidden">
                <div class="text-body-default-heavy text-primary">{{ account.account.name }}</div>
                <div class="text-body-sm text-tertiary text-nowrap text-ellipsis">{{ account.subdomain }}</div>
              </div>
            </div>
          </template>
        </DropdownItem>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
  import type { IAccount } from "~/types/auth";
  import { ComponentSize } from "~/types/global";

  defineOptions({
    name: "ChangeWorkspaceDropdown",
  });
  //@ts-ignore
  const { toggleNavigationBar } = inject("navigationBarOpen");
  const { t } = useI18n();

  const calculatePosition = (cb) => {
    //get main element
    const triggerElm = trigger.value?.el?.getBoundingClientRect();
    if (triggerElm) {
      let left = triggerElm.right;
      let top = triggerElm.top;

      setStyleProperty("top", `${top}px`);
      setStyleProperty("left", `calc(${left}px + 10px)`);
    }

    if (cb && typeof cb === "function") cb();
  };

  const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();

  watch(
    () => showDropdown.value,
    (val) => {
      toggleNavigationBar(val);
    }
  );

  const { getAccount, getAccounts, setAccount } = useAuth();
  const account = getAccount();
  const accounts = getAccounts();

  const setCurrentAccount = (ac: IAccount) => {
    setAccount(ac, isDomainDev());
    handleSubdomain(ac);
  };

  const handleSubdomain = (account: IAccount) => {
    const hostname = useRequestURL().hostname;
    const currentSubdomain = hostname.split(".")[0];
    const domain = hostname.split(".").slice(1).join(".");

    if (currentSubdomain != account.subdomain && !isDomainDev()) {
      $loader.toggle();
      return navigateTo(`https://${account.subdomain}.${domain}`, { external: true });
    }
  };

  const isCurrentAccount = (ac) => {
    return account.account.id === ac.account.id;
  };
</script>
