<template>
  <div>
    <div class="w-full" @click="toggleDropdown" ref="trigger">
      <slot :open="showDropdown"></slot>
    </div>
    <Dropdown
      v-model:open="showDropdown"
      :toggleElement="trigger"
      ref="dropdown"
      :calculatePosition="calculatePosition"
    >
      <div class="flex flex-col space-y-1.5 p-1.5 w-[312px]">
        <div class="flex gap-2.5 p-2 self-stretch">
          <LayoutNavigationUserAvatar size="xs" />
          <div class="text-sm font-medium text-primary mr-auto flex flex-col overflow-hidden">
            <span class="truncate">{{ user.name }}</span>
            <span class="text-xs text-tertiary truncate">{{ user.email }}</span>
          </div>
        </div>
        <DropdownItem @click="openSettings">
          {{ $t("preferences") }}
        </DropdownItem>

        <Divider />
        <DropdownItem @click="enableOldUi">
          <div class="flex gap-2 items-center">
            <Icon src="ArrowsCounterClockwise"></Icon>
            <span class="truncate">{{ $t("go_to_old_ui") }}</span>
          </div>
        </DropdownItem>

        <!--

        <div class="flex flex-col self-stretch">
          <DropdownItem @click="openBoarding">
              {{ $t("open_startup_wizard") }}
          </DropdownItem>

          <DropdownItem>
            <NuxtLink class="w-full">
              {{ $t("latest_update", 99) }}
            </NuxtLink>
          </DropdownItem>
        </div>
      -->
        <Divider />

        <div class="flex flex-col">
          <DropdownItem>
            <NuxtLink class="w-full" :to="{ name: RouteName.logout }">
              {{ $t("logout_from_organisation", { organisation: account?.account.name }, 99) }}
            </NuxtLink>
          </DropdownItem>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
  import { useBoarding } from "~/modules/boarding/runtime/service";
  import { RouteName } from "~/types/routes";

  defineOptions({
    name: "UserDropdown",
  });

  const { getUser, getAccount } = useAuth();
  const user = getUser();
  const account = getAccount();
  const openSettings = () => window.$user_settings.open();
  const enableOldUi = () => {
    setCookie({ name: "frontend-version", value: "old", expiration: 60000 * 60 * 24 * 365 });

    //change url to reload
    window.location.href = "/login";
  };

  //@ts-ignore
  const { toggleNavigationBar } = inject("navigationBarOpen");

  const calculatePosition = (cb) => {
    //get main element
    const mainElm = document.querySelector("main")?.getBoundingClientRect();
    if (mainElm) {
      let left = mainElm.left;

      const dropdownHeight = dropdown.value?.height || 0;

      setStyleProperty("top", `calc(100% - ${dropdownHeight}px - 1.5rem)`);
      setStyleProperty("left", `calc(${left}px + 0.375rem)`);
    }
    if (cb && typeof cb === "function") cb();
  };

  const { open: openBoarding } = useBoarding();

  const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();

  watch(
    () => showDropdown.value,
    (val) => {
      toggleNavigationBar(val);
    }
  );
</script>
