<template>
  <NuxtLink
    v-if="props.entry.route_key"
    :class="componentClasses"
    :href="props.entry.route"
    :target="props.entry.target || '_self'"
  >
    <LayoutNavigationItemIcon :name="props.entry.icon" :class="iconClasses" />
    <span class="px-1">{{ props.entry.label }}</span>
  </NuxtLink>
  <button v-else :class="componentClasses" @click="props.entry.action" type="button">
    <LayoutNavigationItemIcon :name="props.entry.icon" :class="iconClasses" />
    <span class="px-1">{{ props.entry.label }}</span>
  </button>
</template>

<script setup lang="ts">
  import { LayoutNavigationItemIcon, NuxtLink } from "#components";
  const route = useRoute();

  defineOptions({
    name: "LayoutNavigationItem",
  });

  const props = withDefaults(
    defineProps<{
      entry: ISidebarEntry | ISidebarAction;
      expanded: boolean;
    }>(),
    {
      expanded: false,
    }
  );

  const componentClasses = computed(() => {
    let classes =
      "h-[38px] focus:shadow-focus-blue group flex w-full items-center gap-1.5 whitespace-nowrap rounded-[10px] p-1 text-body-default text-secondary hover:text-primary hover:bg-01 transition-all focus:text-gray-900 focus:outline-none active:outline-none ";

    if (route.path.includes(props.entry.route_key)) {
      classes += "active bg-surface-lvl-00 border border-01 shadow-lvl-01";
    }

    if (!props.expanded) {
      classes += " !w-[38px]";
    }

    return classes;
  });

  const iconClasses = "text-secondary flex-shrink-0 rounded-[10px]";
</script>
