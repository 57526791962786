<template>
  <Sidebar ref="sidebar">
    <template #header>
      <div class="flex h-topbar w-max flex-row items-center gap-0.5 overflow-hidden">
        <LayoutNavigationWorkspace :expanded="expanded" />
        <div class="flex-shrink-0" v-if="expanded">
          <Tooltip :text="t('lock_navigation')">
            <ClientOnly>
              <Button variant="tertiary" icon-only @click="lock" :active="locked">
                <Icon src="bold/Sidebar" />
              </Button>
            </ClientOnly>
          </Tooltip>
        </div>
      </div>
    </template>
    <div class="flex flex-col">
      <template v-for="(entryGroup, index) in visibleEntries">
        <div class="flex flex-col gap-1 pt-2 first:pb-2">
          <LayoutNavigationItem
            v-for="entry in entryGroup"
            :key="entry.route_key"
            :entry="entry"
            :expanded="expanded"
          />
        </div>
      </template>
    </div>
    <template #footer>
      <div class="mt-auto flex flex-col gap-0.5">
        <div class="flex flex-col gap-1">
          <LayoutNavigationItem v-for="action in actions" :entry="action" :expanded="expanded" />
        </div>
        <LayoutNavigationUser :expanded="expanded" />
      </div>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
  import { computed, ref } from "vue";
  import { useI18n } from "vue-i18n";
  import { RouteName } from "~/types/routes";

  defineOptions({
    name: "LayoutNavigation",
  });

  const { t } = useI18n();
  const { isAccountBusiness } = useAuth();

  const sidebar = ref<HTMLElement>();
  const expanded = computed(() => sidebar.value?.expanded);
  const lock = () => sidebar.value?.lock();
  const locked = computed(() => sidebar.value?.locked);

  const entries: ISidebarEntry[][] = [
    [
      {
        label: t("workspace_settings"),
        icon: "regular/gear",
        action: process.client ? $workspace_settings.open : undefined,
      },
    ],
    [
      {
        label: t("sidebar.shipments"),
        route: "/shipments",
        icon: "custom/regular/forsendelse",
        route_key: RouteName.shipments,
        hidden: !useHasPageAccess(RouteName.shipments),
      },
      {
        label: t("sidebar.returns"),
        route: "/returns",
        icon: "custom/regular/returnering",
        route_key: RouteName.returns,
        hidden: !useHasPageAccess(RouteName.returns),
      },
      {
        label: t("sidebar.tickets"),
        route: "/tickets",
        icon: "regular/ClipboardText",
        route_key: RouteName.tickets,
        hidden: !useHasPageAccess(RouteName.tickets),
      },
      /*{
       label: t("sidebar.warehouse_management"),
       route: "/warehouse",
       icon: "regular/warehouse",
       route_key: "warehouse_management",
     },*/
      {
        label: t("sidebar.pickup"),
        route: "/sortings",
        icon: "regular/ArrowsLeftRight",
        route_key: RouteName.sortings,
        hidden: !useHasPageAccess(RouteName.sortings),
      },
      /*{
       label: t("sidebar.insight"),
       route: "/insight",
       icon: "regular/chart_donut",
       route_key: "insight",
     },*/
    ],
  ];

  const visibleEntries = entries.map((entryGroup) => entryGroup.filter((entry) => !entry.hidden));

  const actions: (ISidebarAction | ISidebarEntry)[] = [
    /*{
     label: t("sidebar.app_center"),
     icon: "regular/circles_three_plus",
     action: () => {
       $toast.add({
         title: t("not_yet_available"),
       });
     },
     key: "app_center",
   },
  /* {
     label: t("sidebar.notifications"),
     icon: "regular/Tray",
     action: () => {
       $toast.add({
         title: t("not_yet_available"),
       });
     },
     key: "notifications",
   },*/
    {
      label: t("sidebar.open_help_center"),
      icon: "regular/Lifebuoy",
      route_key: "help",
      route: "https://help.homerunner.com",
      target: "_blank",
      key: "help",
    },
  ];
</script>
