<template>
  <LayoutNavigationUserDropdown v-slot="slotProps">
    <div
      class="dropdown-toggle relative flex cursor-pointer items-center gap-2.5 p-1 rounded-[10px] hover:bg-01"
      :class="{
        'w-full': expanded,
        'w-9': !expanded,
        'bg-surface-lvl-00 shadow-lvl-01': slotProps.open,
      }"
    >
      <LayoutNavigationUserAvatar size="2xs" />

      <div class="h-[37px] w-full px-1 truncate">
        <div class="text-body-default-heavy text-primary truncate">
          {{ user?.name }}
        </div>
        <div class="text-body-sm text-quarterary truncate">
          {{ user?.email }}
        </div>
      </div>
      <div class="flex items-center">
        <Icon
          class="transition-transform"
          src="bold/CaretDown"
          :size="ComponentSize.sm"
          :class="{
            '-rotate-90 transform': slotProps.open,
          }"
        />
      </div>
    </div>
  </LayoutNavigationUserDropdown>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  defineOptions({
    name: "LayoutNavigationUser",
  });
  const props = withDefaults(
    defineProps<{
      expanded: boolean;
    }>(),
    {
      expanded: false,
    }
  );
  const { getUser } = useAuth();
  const user = getUser();
</script>
