<template>
  <div>
    <div class="w-full" @click="toggleDropdown" ref="trigger">
      <slot :open="showDropdown"></slot>
    </div>
    <Dropdown
      v-model:open="showDropdown"
      :toggleElement="trigger"
      ref="dropdown"
      :calculatePosition="calculatePosition"
    >
      <div class="space-y-1.5 dropdown__content max-w-[312px] p-1.5">
        <div class="flex gap-2.5 p-2 self-stretch">
          <LayoutNavigationWorkspaceAvatar size="xs" />
          <div class="text-sm font-medium text-primary mr-auto flex flex-col overflow-hidden justify-center">
            <span class="truncate">{{ accountName }}</span>
            <span class="text-xs text-tertiary text-nowrap text-ellipsis truncate" v-if="isAccountBusiness">{{
              currentDomain
            }}</span>
          </div>
        </div>
        <template v-if="isAccountBusiness">
          <Divider />
          <div class="text-body-sm text-tertiary px-3">
            <i18n-t :keypath="accountLevelText" tag="p">
              <template v-slot:organisation>
                <span>{{ accountName }}</span>
              </template>
              <template v-slot:carrier>
                <span>{{ pickUpCarrier }}</span>
              </template>
            </i18n-t>
          </div>
        </template>
        <Divider />
        <div>
          <DropdownItem v-if="isAccountBusiness" @click="openInviteUsers()">
            {{ t("invite_users_to_organisation", { organisation: accountName }) }}
          </DropdownItem>
          <!-- <DropdownItem>
            {{ t("extension", 99) }}
          </DropdownItem>
          -->
          <DropdownItem :href="{ name: RouteName.documents }">
            {{ t("document", 99) }}
          </DropdownItem>
          <DropdownItem :href="{ name: RouteName.invoices }">
            {{ t("invoice", 99) }}
          </DropdownItem>

          <DropdownItem :href="{ name: RouteName.prices }">
            {{ t("prices_overview") }}
          </DropdownItem>
          <DropdownItem @click="openSettings">
            {{ t("workspace_settings", 99) }}
          </DropdownItem>
        </div>
        <Divider />

        <template v-if="hasMultipleWorkspaces">
          <div>
            <LayoutNavigationWorkspaceChangeDropdown />
          </div>
          <Divider />
        </template>

        <div>
          <DropdownItem>
            <NuxtLink class="w-full" :to="{ name: RouteName.logout }">
              {{ $t("logout_from_organisation", { organisation: accountName }, 99) }}
            </NuxtLink>
          </DropdownItem>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
  import { RouteName } from "~/types/routes";

  defineOptions({
    name: "WorkspaceDropdown",
  });
  //@ts-ignore
  const { toggleNavigationBar } = inject("navigationBarOpen");
  const { t } = useI18n();

  const calculatePosition = (cb) => {
    //get main element
    const mainElm = document.querySelector("main")?.getBoundingClientRect();
    if (mainElm) {
      let left = mainElm.left;

      setStyleProperty("top", `1.5rem`);
      setStyleProperty("left", `calc(${left}px + 0.375rem)`);
    }

    if (cb && typeof cb === "function") cb();
  };

  const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();

  watch(
    () => showDropdown.value,
    (val) => {
      toggleNavigationBar(val);
    }
  );

  const { getAccount, services, isAccountBusiness: checkIsAccountBusiness, hasMultipleWorkspaces } = useAuth();
  const account = getAccount();
  const isAccountBusiness = checkIsAccountBusiness();

  const accountName = computed(() => account?.account.name);
  const currentDomain = useRequestURL().hostname;
  const openSettings = () => window.$workspace_settings.open();
  const openInviteUsers = () => {
    window.$inviteusers.open();
  };
  const pickUpCarrier = services.value?.transfer_delivery_method || t("not_available");
  const accountLevelText = computed(() => {
    switch (useAuth().accountLevel.value) {
      case "SMV":
        return "account_level.smv.description";
      case "C2C":
        return "account_level.c2c.description";
      case "ENTERPRISE":
        return "account_level.enterprise.description";
      case "SCALE_UP":
        return "account_level.scale_up.description";
      case "DFM":
        return "account_level.dfm.description";
      default:
        return "";
    }
  });
</script>
